import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';

import { Divider, Text } from 'wix-ui-tpa/cssVars';

import { st, classes } from './Widget.st.css';
import { DailyAgendaViewModel } from '../viewModel/viewModel';

export type ControllerProps = {
  viewModel: { headerViewModel: { title: string; titleTagName: string } };
  // Here, props passed via controller's `setProps` should be defined.
};

const Widget: FC<WidgetProps<DailyAgendaViewModel>> = ({
  headerViewModel: { title, titleTagName },
}) => {
  return (
    <div className={st(classes.root, {})} data-hook="DailyAgenda-wrapper">
      <Text className={classes.header} tagName={titleTagName}>
        {title}
      </Text>
      <Divider className={classes.divider} />
      Loading...
    </div>
  );
};

export default Widget;
